.search{
  padding-bottom:120px;
}

.search__fixedTopBar{
  height:60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  position: fixed;
  width: 602px;
  background-color: var(--lightGrey);

  padding-left:11px;
  padding-right:11px;
}

.search__fixedTopBar_searchBar{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  border-radius: 99px;
  border: 1px solid var(--exitBorderBottomGrey);
  background-color: var(--white);
}

.search__fixedTopBar_searchBar_icon{
width:40px;
height: 40px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
padding-left:10px;
}

.search__fixedTopBar_searchBar_input::placeholder{
color: var(--black);
font-weight: var(--mediumWeight);
font-size: var(--mediumFontSize);
width: 100%;
border:none;

}

.search__fixedTopBar_searchBar_input{
border:none;
font-weight: var(--mediumWeight);
color: var(--exitGrey);
font-size: var(--mediumFontSize);
border-radius:99px;
  height: 40px;
  width: 100%;
  padding-left: 5px;;
  }

  .search__fixedTopBar_searchBar_closeButton{
    width: 80px;
    height:100%;
    display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
  }

  .search__scrollContent{
    height: 1000px;
    padding-top: 60px;
  }

  .search__scrollContent_contentbox{
    padding-left:11px;
    padding-right:11px;
  
  }
  
  .search__scrollContent_contentbox_title{
    padding-top:16px;
    padding-bottom:11px;
    font-weight: var(--boldWeight);
color: var(--black);
font-size: var(--XLFontSize);
  }

  .search__scrollContent_contentbox_ownTag{
    margin-top:10px;
  }

  .search__infoLinks{
    width:170px;
    padding-left: 11px;
  }