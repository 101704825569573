.leftColumn{
  position: fixed;
  width: calc(282px - var(--leftSidebarDecreaseNumber));
}


.home {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  height:100%;
}

.home__scrollPageLeft{
  width: 602px;
  height:100%;
}

.home__fixedPageRight{
  width: 399px;
  position: fixed;
  margin-left: 602px;
}

.contacts__scrollPageLeft{
  width: 399px;
}

.contacts__fixedPageRight{
  width: 602px;
  position: fixed;
  margin-left: 399px;
}

.topNavBarOnlyOnMobile{
  position: fixed;
bottom:0;
height: calc(52px + env(safe-area-inset-bottom));
padding: env(safe-area-inset);
width:100%;

background-color: var(--lightGrey);
z-index:9;
}

.topNavBarOnlyOnMobile__loggedIn{
  width:100%;
  height:100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.topNavBarOnlyOnMobile__loggedIn_col{
  width: 25%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:52px;

  font-size: var(--xsFontSize);
  color: var(--exitGrey);
}

.topNavBarOnlyOnMobile__loggedOut{
  width:100%;
  height:100%;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 9px;

  padding-right:9px;

}

.topNavBarOnlyOnMobile__loggedOut_left{
font-weight: var(--semiboldWeight);
color: var(--accentBlue);
font-size: var(--XLFontSize);
letter-spacing: var(--tighterLetterSpacing);
height:52px;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.topNavBarOnlyOnMobile__loggedOut_right{
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
gap:11px;
height:52px;

}


.topNavBarOnlyOnMobile__loggedOut_right_login{
  color:var(--black);
  font-weight: var(--semiboldWeight);
  padding-top:11px;
  padding-left:22px;
  padding-right: 22px;
  padding-bottom:11px;
  border-radius:12px;
}

.topNavBarOnlyOnMobile__loggedOut_right_register{
  background-color: var(--accentBlue);
  font-weight: var(--mediumWeight);

  color:var(--white);
  padding-top:11px;
  padding-left:22px;
  padding-right: 22px;
  padding-bottom:11px;
  border-radius:12px;
}