.appointmentPreview{
width: 100%;
background-color: var(--white);
padding-top:11px;
padding-bottom:12px;
padding-left:14px;
padding-right: 14px;
border-radius:12px;

display:flex;
flex-direction: row;
justify-content: space-between;
align-items: center;

margin-bottom:3px;

cursor: pointer;

border-bottom: 1px solid var(--exitBorderBottomGrey)
}

.appointmentPreview:hover{
  background-color: var(--greyBoxOnGrey);
}

.appointmentPreview_active{
  width: 100%;
  background-color: var(--greyBoxOnGrey);
  padding-top:11px;
  padding-bottom:12px;
  padding-left:14px;
  padding-right: 14px;
  border-radius:12px;
  
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  margin-bottom:3px;
  
  cursor: pointer;
  border-bottom: 1px solid var(--exitBorderBottomGrey)

  }

  .appointmentPreview_iconBox{
    width: 45px;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .appointmentPreview_wrapper{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

.appointmentPreview__left{
  text-align:left;
}

.appointmentPreview__left_top{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);

}

.appointmentPreview__left_bottom{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--regularFontSize);
}

.appointmentPreview__right{
text-align:right;
}

.appointmentPreview__right_top{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);
}

.appointmentPreview__right_bottom{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--regularFontSize);
}