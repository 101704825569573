.calenderConfig {
width:100%;
padding-bottom:120px;
height:100%;
}

.calenderConfig__fixedTopBar{
height:60px;
width: 100%;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: row;

padding-left:11px;
padding-right:11px;
position: fixed;
z-index:1;
background-color: var(--lightGrey);
max-width:600px;
}

.calenderConfig__fixedTopBar_leftColumn{
  width: 100%;

}

.calenderConfig__fixedTopBar_leftColumn_wrapper{
  display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: row;
}

.calenderConfig__fixedTopBar_leftColumn_topText{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--XLFontSize);
}

.calenderConfig__fixedTopBar_leftColumn_bottomText{
  font-weight: var(--mediumWeight);
  color: var(--accentBlue);
  font-size: var(--xsFontSize);
}

.calenderConfig__fixedTopBar_middleColumn{

}

.calenderConfig__fixedTopBar_middleColumn_newQuestionButton{
  width: 150px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  padding-left: 22px;
  padding-right:22px;
  padding-top: 7px;
  padding-bottom: 7px;
  
  border-radius: 99px;
  
  background-color: var(--greyBoxOnGrey);
}

.calenderConfig__fixedTopBar_middleColumn_newQuestionButton_icon{

}

.calenderConfig__fixedTopBar_middleColumn_newQuestionButton_text{
  font-weight: var(--regularWeight);
  color: var(--exitGrey);
  font-size: var(--smallFontSize);
  padding-left:5px;
}

.calenderConfig__fixedTopBar_rightColumn{

}

.calenderConfig__fixedTopBar_rightColumn_saveChangesButtonBox{

}

.calenderConfig__fixedTopBar_rightColumn_savesChangesButtonBox_savesChangesButton{
  background-color: var(--accentBlue);
width:140px;
height:35px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
border-radius:99px;

font-weight: var(--mediumWeight);
color: var(--white);
font-size: var(--mediumFontSize);

cursor:pointer;
}

.calenderConfig__fixedTopBar_rightColumn_savesChangesButtonBox_savesChangesButton_disabled{
width:140px;
height:35px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
border-radius:99px;

font-weight: var(--mediumWeight);
color: var(--exitGrey);
font-size: var(--mediumFontSize);

cursor:not-allowed;

text-decoration: line-through;
}

.calenderConfig__scrollContent{
height: 100%;
width: 100%;

overflow-y: scroll;
overflow-x: hidden;
position: fixed;
max-width: 602px;

padding-bottom: 100px;


padding-top:60px;
padding-left:5px;
padding-right:5px;
z-index: 0;

}

.calenderConfig__scrollContent_contentBox{
padding-left: 16px;
padding-right:16px;
padding-top: 18px;
padding-bottom: 18px;

width: 100%;

background-color: var(--white);
border-radius: 12px;

margin-bottom: 11px;
}

.calenderConfig__scrollContent_contentBox_title{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--LargeFontSize);
}

.calenderConfig__scrollContent_contentBox_descriptionText{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--RegularSize);
}

.calenderConfig__scrollContent_contentBox_content{

}

.calenderConfig__scrollContent_contentBox_content_calenderActiveBox{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding-top:8px;
}

.calenderConfig__scrollContent_contentBox_content_calenderActiveBox_RadioButton{
background-color: var(--greyBoxOnGrey);
width:40px;
height:40px;
border-radius: 99px;
margin-right: 16px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
}

.calenderConfig__scrollContent_contentBox_content_calenderActiveBox_RadioButton_clickedBox{
  background-color: var(--accentBlue);
width:10px;
height:10px;
border-radius: 99px;
}

.calenderConfig__scrollContent_contentBox_content_calenderActiveBox_Text{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--mediumSize);
}

.calenderConfig__scrollContent_contentBox_content_service{

}

.calenderConfig__scrollContent_contentBox_content_service_titleBox{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;


}

.calenderConfig__scrollContent_contentBox_content_service_titleBox_number{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--mediumSize);

  padding-left: 10px;
  padding-right:10px;
}

.calenderConfig__scrollContent_contentBox_content_service_inputBox{
  width: 100%;

  height: 40px;
  border:1px solid var(--exitBorderBottomGrey);
  border-radius: 20px;
  margin-right:10px;

  margin-top:11px;
  margin-bottom:11px;
}

.calenderConfig__scrollContent_contentBox_content_service_inputBox_input{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--mediumSize);

  width:100%;
  height: 100%;
  border:1px solid var(--exitBorderBottomGrey);
border-radius: 20px;
padding-left: 15px;
padding-right:15px;
}

.calenderConfig__scrollContent_contentBox_content_service_priceDurationBox{
  margin-bottom:11px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_text{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--mediumSize);

  margin-right:15px;
}

.calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_inputBox{
  width: 90px;

  height: 40px;
  border:1px solid var(--exitBorderBottomGrey);
  border-radius: 20px;
  margin-right:6px;
}

.calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_inputBoxLong{
  width:300px;

  height: 40px;
  border:1px solid var(--exitBorderBottomGrey);
  border-radius: 20px;
  margin-right:6px;
}

.calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_inputBox_input{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--mediumSize);

  width:100%;
  height: 100%;
  border:1px solid var(--exitBorderBottomGrey);
border-radius: 20px;
padding-left: 15px;
padding-right:15px;

text-align: right;

}

.calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_inputBoxLong_inputLong{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--mediumSize);

  width:100%;
  height: 100%;
  border:1px solid var(--exitBorderBottomGrey);
border-radius: 20px;
padding-left: 15px;
padding-right:15px;

text-align: left;

}

.calenderConfig__scrollContent_contentBox_addMoreServices{
  height:40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border:2px solid var(--exitBorderBottomGrey);
border-radius: 20px;

  font-weight: var(--mediumWeight);
  color: var(--accentBlue);
  font-size: var(--mediumSize);
}

.calenderConfig__scrollContent_contentBox_content_timeBox{
  margin-bottom:11px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding-top:6px;
  padding-bottom:6px;
}

.calenderConfig__scrollContent_contentBox_content_timeBox_text{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--mediumSize);


}

.calenderConfig__scrollContent_contentBox_content_timeBox_inputBox{
  width: 50px;

  height: 40px;
  border:1px solid var(--exitBorderBottomGrey);
  border-radius: 20px;
  margin-right:3px;
  margin-left:3px;

}

.calenderConfig__scrollContent_contentBox_content_timeBox_inputBox_input{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--mediumSize);

  width:100%;
  height: 100%;
  border:1px solid var(--exitBorderBottomGrey);
border-radius: 20px;
padding-left: 3px;
padding-right:3px;
text-align: center;
}

