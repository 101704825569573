.tag{
  background-color: var(--greyBoxOnGrey);
  color: var(--black);
  border-radius: 8px;
  font-size: var(--regularFontSize);
  font-weight: var(--semiboldWeight);
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 11px;
  padding-bottom: 11px;

  cursor: pointer;
}

.tag__active {
  background-color: var(--accentBlue);
  color: var(--white);
  border-radius: 8px;
  font-size: var(--regularFontSize);
  font-weight: var(--semiboldWeight);
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 11px;
  padding-bottom: 11px;

  cursor: pointer;
}