.themeboxComponent{
background-color: var(--white);
padding: 17px;
border-radius: 12px;
margin-top:11px;
border-bottom: 1px solid var(--exitBorderBottomGrey);
width: 100%;
}

.themeboxComponent__topBar{

}

.themeboxComponent__topBar_headline{
 font-size: var(--XLFontSize); 
}