.sidebar {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  padding-right: 12px;
}

.sidebar__top{
  width:100%;
  height:60px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-bottom: 9px;
}

.sidebar__top_logo{
  font-weight: var(--semiboldWeight);
  color: var(--accentBlue);
  font-size: var(--XXLFontSize);
  letter-spacing: var(--tighterLetterSpacing);
  padding-left:9px;
}

.sidebar__middle{
width: 100%;
height: auto;

padding-bottom: 4px;
border-bottom: 1px solid var(--exitBorderBottomGrey);
}

.sidebar__middle_navigationLinks{

}

.sidebar__middle_navigationLinks_link{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  height:52px;

  cursor: pointer;

}

.sidebar__middle_navigationLinks_link_active{
  
}

.sidebar__middle_navigationLinks_link_icon{
padding-left:13px;
padding-right:13px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
}

.sidebar__middle_navigationLinks_link_text{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--regularFontSize);
}

.sidebar__bottom{
  width: 100%;
  height: auto;
}

.sidebar__bottom_similarExperts{
}


.sidebar__bottom_similarExperts_titleText{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);
  letter-spacing: var(--noLetterSpacing);

  padding-top: 20px;
  padding-bottom: 11px;
}

.sidebar__bottom_similarExperts_expert{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  padding-top:5px;
}

.sidebar__bottom_similarExperts_expert_imagebox{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-right:8px;
}

.sidebar__bottom_similarExperts_expert_imagebox_image{
  width:45px;
  height:45px;
  border-radius: 99px;
}

.sidebar__bottom_similarExperts_expert_description{

}

.sidebar__bottom_similarExperts_expert_description_name{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--smallFontSize);
}

.sidebar__bottom_similarExperts_expert_description_expertFor{
  font-weight: var(--mediumWeight);
  color: var(--accentBlue);
  font-size: var(--smallFontSize);

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.sidebar__bottom_similarExperts_expert_description_expertFor_text{

}

.sidebar__bottom_similarExperts_expert_description_expertFor_tag{
margin-left:5px;
margin-top:0px;

padding-left: 11px;
padding-right:11px;
padding-top: 2px;
padding-bottom: 2px;

border-radius: 8px;

background-color: var(--greyBoxOnGrey);
font-weight: var(--mediumWeight);
color: var(--black);
font-size: var(--smallFontSize);
}

.sidebar__logins{
  margin-top:20px;
  width: 100%;
  height: auto;

  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap:8px;
}

.sidebar__logins_register{
width:100%;
}

.sidebar__logins_login{
width:100%;
}

.sidebar__footer{
  height:auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

.sidebar__footer_links{
  padding-top:35px;

  font-size: var(--xsFontSize);
  font-weight: var(--mediumWeight);
  color: var(--hidingGrey);
}