.answer{
  padding-top:17px;
  padding-left: 17px;
  padding-right:17px;
  padding-bottom: 12px;
  max-width: 100%;
  width:100%;
}

.answer__scrollContent_element_topBar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.answer__scrollContent_element_topBar_tags{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap:8px;
  flex-wrap: wrap;

}

.answer__scrollContent_element_topBar_tags_tag{
  background-color: var(--greyBoxOnWhite);
  font-weight: var(--smallWeight);
  color: var(--exitGrey);
  font-size: var(--smallFontSize);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 8px;
}

.answer__scrollContent_element_topBar_moreButton{

}

.answer__scrollContent_element_question{
  font-weight: var(--semiboldWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);
  padding-top: 11px;
  padding-bottom: 11px;
}

.answer__scrollContent_element_answer{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;

  padding-bottom: 15px;
  max-width: 100%;
  width:100%;
}

.answer__scrollContent_element_answer_answererProfilepicbox{
margin-right: 13px;
}

.answer__scrollContent_element_answer_answererProfilepicbox_image{
  width: 31px;
  height: 31px;
  border-radius: 99px;
}

.answer__scrollContent_element_answer_answerText{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--regularFontSize);
  max-width: 100%;
  width:100%;  flex-wrap: wrap;

  white-space: pre-wrap;  
  white-space: -moz-pre-wrap; 
  white-space: -pre-wrap; 
  white-space: -o-pre-wrap; 
  word-wrap: break-word; 
}

.answer__scrollContent_element_reply{
  border-top: 1px solid var(--exitBorderBottomGrey);
  padding-top: 13px;
  padding-bottom: 15px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.answer__scrollContent_element_reply_ownImageThumbnailbox{
  margin-right: 13px;
}

.answer__scrollContent_element_reply_ownImageThumbnailbox_image{
  width: 31px;
  height: 31px;
  border-radius: 99px;
  margin-top: 5px;
}

.answer__scrollContent_element_replyBox{
  width: 90%;
  height: 40px;

  
  display: flex;
  justify-content: flex-start;
  align-items: center;

} 

.answer__scrollContent_element_replyBox_input::placeholder{
  color: var(--accentBlue);
}

.answer__scrollContent_element_replyBox_input{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);

  padding-left: 22px;
  width: 100%;
  height: 100%;
  border: 1px solid var(--exitBorderBottomGrey);
  border-radius: 99px;

}