.question{
padding-top:17px;
}

.question__scrollContent_element_topBar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-left:17px;
padding-right: 17px;
}

.question__scrollContent_element_topBar_tags{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap:8px;

  flex-wrap: wrap;
}

.question__scrollContent_element_topBar_tags_tag{
  background-color: var(--greyBoxOnWhite);
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--regularFontSize);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 8px;
}

.question__scrollContent_element_topBar_moreButton{

}

.question__scrollContent_element_question{
  font-weight: var(--semiboldWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);
  padding-top: 11px;
  padding-bottom: 11px;

  padding-left:17px;
padding-right: 17px;
}

.question__scrollContent_element_question:hover{
  background-color: var(--greyBoxOnWhite);
  cursor: pointer;

}

.question__scrollContent_element_answer{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;

  padding-bottom: 15px;
  padding-left:17px;
padding-right: 17px;

}

.question__scrollContent_element_answer:hover{
  background-color: var(--greyBoxOnWhite);
  cursor: pointer;
}

.question__scrollContent_element_answer_buttonBox{
  width:auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
}

.question__scrollContent_element_answer_buttonBox_button{
  border-radius:99px;
  width: 50px;
  height:50px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.question__scrollContent_element_answer_answererProfilepicbox{
margin-right: 13px;
}

.question__scrollContent_element_answer_answererProfilepicbox_image{
  width: 31px;
  height: 31px;
  border-radius: 99px;
}

.question__scrollContent_element_answer_answerText{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--regularFontSize);
  width:100%;
}

.question__scrollContent_element_answer_answerText_image{
  width:100%;
  height:auto;
  border-radius:12px;
}

.question__scrollContent_element_reply{
  border-top: 1px solid var(--exitBorderBottomGrey);
  padding-top: 13px;
  padding-bottom: 25px;
  padding-left:17px;
padding-right: 17px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
}

.question__scrollContent_element_reply_imagePreviewbox{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.question__scrollContent_element_reply_imagePreviewbox_imagePreview{
  width: calc(100% - 41px); height: auto;
  border-radius: 20px;
  border: 1px solid var(--exitBorderBottomGrey);

}

.question__scrollContent_element_reply_ownImageThumbnailbox{
  margin-right: 13px;
}

.question__scrollContent_element_reply_ownImageThumbnailbox_image{
  width: 31px;
  height: 31px;
  border-radius: 99px;
  margin-top: 5px;
}

.question__scrollContent_element_replyBox{
  border: 1px solid var(--exitBorderBottomGrey);
  border-radius: 20px;
width: 400px;
min-height: 40px;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
} 

.question__scrollContent_element_replyBox_input_flexWrapper{
  display: flex;
justify-content: flex-start;
align-items: flex-end;
flex-direction: row;
width:100%;
min-height:37px;
}

.question__scrollContent_element_replyBox_input::placeholder{
  color: var(--accentBlue);
}

.question__scrollContent_element_replyBox_input{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);

  padding-left: 18px;
  width: 100%;
  min-height:32px;

  overflow: hidden;
  resize: none;
  white-space: pre-wrap;
  word-break: break-word;

  
}

.question__scrollContent_element_replyBox_input[contenteditable]:empty::before{
  content: "Frage beantworten";
  color: var(--accentBlue);
  cursor: text;
}

.question__scrollContent_element_replyBox_addImage{
width:50px;
height:100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
}

.question__scrollContent_element_replyBox_addImage_input{
display:none;
}

.question__scrollContent_element_replyBox_addImage_label{

width: 100%;
height: 100%;
font-size: 30px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
}


.question__scrollContent_element_submitButtonBox{
width: 100px;
height:100%;
display: flex;
justify-content: flex-end;
align-items: center;
flex-direction: row;
margin-left:8px;
}

.question__scrollContent_element_submitButtonBox_submitButton{
  display:none;
  justify-content: center;
align-items: center;
flex-direction: row;
  width:100px;
  height:40px;
  background-color: var(--accentBlue);
  border-radius: 99px;
  color: var(--white);

  cursor: pointer;
}

.question__scrollContent_element_submitButtonBox_submitButton_disabled{
  display:none;
  justify-content: center;
align-items: center;
flex-direction: row;
  width:100px;
  height:40px;
  background-color: var(--exitBorderBottomGrey);
  border-radius: 99px;
  color: var(--white);

  cursor:not-allowed;
}