.relevantTag{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  padding-top:5px;
}

.relevantTag_hashtagBox{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-right:22px;
  padding-left:22px;
}

.relevantTag_hashtagBox_hashtag{
font-size: 51px;
}

.relevantTag_description{
  flex-flow: wrap;
}

.relevantTag_description_name{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: 25px;
}

.relevantTag_description_expertFor{
  font-weight: var(--regularWeight);
  color: var(--accentBlue);
  font-size: var(--regularFontSize);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  flex-flow: wrap;
gap: 5px;
}

.relevantTag_description_expertFor_text{

}

.relevantTag_description_expertFor_tag{
margin-left:5px;
margin-top:0px;

padding-left: 11px;
padding-right:11px;
padding-top: 2px;
padding-bottom: 2px;

border-radius: 8px;

background-color: var(--greyBoxOnGrey);
font-weight: var(--mediumWeight);
color: var(--black);
font-size: var(--smallFontSize);
}