.contacts {
  width:100%;
  padding-bottom:120px;
  
  }
  
  .contacts__fixedTopBar{
  height:60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  padding-left:11px;
  padding-right:11px;
  }
  
  .contacts__fixedTopBar_leftColumn{
    width: 50%;
  
  }
  
  .contacts__fixedTopBar_leftColumn_topText{
    font-weight: var(--mediumWeight);
    color: var(--black);
    font-size: var(--XLFontSize);
  }
  
  .contacts__fixedTopBar_leftColumn_bottomText{
    font-weight: var(--mediumWeight);
    color: var(--accentBlue);
    font-size: var(--xsFontSize);
  }
  
  .contacts__fixedTopBar_middleColumn{
  
  }
  
  .contacts__fixedTopBar_middleColumn_newQuestionButton{
    width: 150px;
  
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  
    padding-left: 22px;
    padding-right:22px;
    padding-top: 7px;
    padding-bottom: 7px;
    
    border-radius: 99px;
    
    background-color: var(--greyBoxOnGrey);
  }
  
  .contacts__fixedTopBar_middleColumn_newQuestionButton_icon{
  
  }
  
  .contacts__fixedTopBar_middleColumn_newQuestionButton_text{
    font-weight: var(--regularWeight);
    color: var(--exitGrey);
    font-size: var(--smallFontSize);
    padding-left:5px;
  }
  
  .contacts__fixedTopBar_rightColumn{
  
  }
  
  .contacts__fixedTopBar_rightColumn_sortButton{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width:auto;
    padding-right: 11px;
  }
  
  .contacts__fixedTopBar_rightColumn_sortButton_icon{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 35px;
  }
  
  .contacts__fixedTopBar_rightColumn_sortButton_text{
  width: auto;
  }
  
  .contacts__scrollContent{
  height: 100%
  }

  .contacts__scrollContent_infoText{
    padding-left:11px;
    padding-right:11px;
  }
  
  .contacts__scrollContent_element{
  background-color: var(--white);
  padding: 17px;
  border-radius: 12px;
  margin-top:11px;
  border-bottom: 1px solid var(--exitBorderBottomGrey);
  width: 595px;
  }
  
  .contacts__scrollContent_element_topBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  
  .contacts__scrollContent_element_topBar_tags{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    gap:8px;
  }
  
  .contacts__scrollContent_element_topBar_tags_tag{
    background-color: var(--greyBoxOnWhite);
    font-weight: var(--smallWeight);
    color: var(--exitGrey);
    font-size: var(--smallFontSize);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 17px;
    padding-right: 17px;
    border-radius: 8px;
  }
  
  .contacts__scrollContent_element_topBar_moreButton{
  
  }
  
  .contacts__scrollContent_element_question{
    font-weight: var(--semiboldWeight);
    color: var(--black);
    font-size: var(--mediumFontSize);
    padding-top: 11px;
    padding-bottom: 11px;
  }
  
  .contacts__scrollContent_element_answer{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
  
    padding-bottom: 15px;
  
  }
  
  .contacts__scrollContent_element_answer_answererProfilepicbox{
  margin-right: 13px;
  }
  
  .contacts__scrollContent_element_answer_answererProfilepicbox_image{
    width: 31px;
    height: 31px;
    border-radius: 99px;
  }
  
  .contacts__scrollContent_element_answer_answerText{
    font-weight: var(--regularWeight);
    color: var(--black);
    font-size: var(--regularFontSize);
  }
  
  .contacts__scrollContent_element_reply{
    border-top: 1px solid var(--exitBorderBottomGrey);
    padding-top: 13px;
    padding-bottom: 15px;
  
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
  }
  .contacts__scrollContent_element_reply_ownImageThumbnailbox{
    margin-right: 13px;
  }
  
  .contacts__scrollContent_element_reply_ownImageThumbnailbox_image{
    width: 31px;
    height: 31px;
    border-radius: 99px;
    margin-top: 5px;
  }
  
  .contacts__scrollContent_element_replyBox{
    width: 90%;
    height: 40px;
  
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
  
  } 
  
  .contacts__scrollContent_element_replyBox_input::placeholder{
    color: var(--accentBlue);
  }
  
  .contacts__scrollContent_element_replyBox_input{
    font-weight: var(--regularWeight);
    color: var(--black);
    font-size: var(--mediumFontSize);
  
    padding-left: 22px;
    width: 100%;
    height: 100%;
    border: 1px solid var(--exitBorderBottomGrey);
    border-radius: 99px;
  
  }