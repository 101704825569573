.detailAppointment {
  width:602px;
  padding-bottom:120px;
  height:100%;
  }
  
  .detailAppointment__fixedTopBar{
    height:60px;
    width: 602px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding-left:22px;
  color:var(--black);
  position: fixed;
}

  .detailAppointment__fixedTopBar_blueHighlighted{
  height:60px;
  width: 602px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding-left:22px;

  background-color: var(--accentBlue);
  color: var(--white);
  position: fixed;
z-index:1;
  }

  .detailAppointment__fixedTopBar_greenHighlighted{
    height:60px;
    width: 602px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding-left:22px;
  
    background-color: var(--accentGreen);
    color: var(--white);
    position: fixed;
z-index:1;
    }
  
  .detailAppointment__fixedTopBar_leftColumn{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
  
  .detailAppointment__fixedTopBar_leftColumn_topText{
    font-weight: var(--mediumWeight);
    font-size: var(--XLFontSize);
  }
  
  .detailAppointment__fixedTopBar_leftColumn_bottomText{
    font-weight: var(--mediumWeight);
    color: var(--accentBlue);
    font-size: var(--xsFontSize);
  }
  
  .detailAppointment__fixedTopBar_middleColumn{
  
  }
  
  .detailAppointment__fixedTopBar_middleColumn_newQuestionButton{
    width: 150px;
  
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  
    padding-left: 22px;
    padding-right:22px;
    padding-top: 7px;
    padding-bottom: 7px;
    
    border-radius: 99px;
    
    background-color: var(--greyBoxOnGrey);
  }
  
  .detailAppointment__fixedTopBar_middleColumn_newQuestionButton_icon{
  
  }
  
  .detailAppointment__fixedTopBar_middleColumn_newQuestionButton_text{
    font-weight: var(--regularWeight);
    color: var(--exitGrey);
    font-size: var(--smallFontSize);
    padding-left:5px;
  }
  
  .detailAppointment__fixedTopBar_rightColumn{
  
  }
  
  .detailAppointment__fixedTopBar_rightColumn_sortButton{
  
  }
  
  .detailAppointment__fixedTopBar_rightColumn_sortButton_icon{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 70px;
  }
  
  .detailAppointment__fixedTopBar_rightColumn_sortButton_text{
  
  }
  
  .detailAppointment__scrollContent{
  height: 100%;
  width: 100%;
  
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  max-width: 602px;
  
  padding-bottom: 100px;
  padding-left:22px;
z-index: 0;
padding-top: 60px;

  }

  .detailAppointment__scrollContent_contentBox, .detailAppointment__scrollContent_ctaBox{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 25px;
    margin-top:16px;
  }

  .detailAppointment__scrollContent_contentBox_title{
    font-weight: var(--mediumWeight);
    color: var(--black);
    font-size: var(--regularFontSize);
  }

  .detailAppointment__scrollContent_contentBox_title_backToSearch{
    background-color: var(--exitBorderBottomGrey);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height:30px;
border-radius:99px;
  }

  .detailAppointment__scrollConntent_contentBox_infoBox{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }

  .detailAppointment__scrollConntent_contentBox_infoBox_blueInfoCircle{
width: 34px;
height:34px;
background-color: var(--accentBlue);
border-radius:99px;
margin-right:11px;
  }

  .detailAppointment__scrollConntent_contentBox_infoBox_greenInfoCircle{
    width: 34px;
    height:34px;
    background-color: var(--accentGreen);
    border-radius:99px;
    margin-right:11px;
      }

  .detailAppointment__scrollConntent_contentBox_infoBox_text{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }



  .detailAppointment__scrollConntent_contentBox_infoBox_text_blueHighlighted{
    color:var(--accentBlue) !important;
  }

  .detailAppointment__scrollConntent_contentBox_infoBox_text_greenHighlighted{
    color:var(--accentGreen) !important;
  }
  
  .detailAppointment__scrollContent_contentBox_userBox{
border: 1px solid var(--exitBorderBottomGrey);
border-radius: 12px;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: row;

padding-left:12px;
padding-top:7px;
padding-bottom: 7px;
padding-right:12px;
  }

  .detailAppointment__scrollContent_contentBox_userBox_imageBox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-right:12px;
  }

  .detailAppointment__scrollContent_contentBox_userBox_imageBox_image{
  width: 40px;
  height:40px;
  border-radius: 99px;
  }

  .detailAppointment__scrollContent_contentBox_userBox_name{
    font-weight: var(--mediumWeight);
    color: var(--black);
    font-size: var(--regularFontSize);
  }

  .detailAppointment__scrollContent_contentBox_dateBox, .detailAppointment__scrollContent_contentBox_serviceBox, .detailAppointment__scrollContent_contentBox_noteBox{
    font-weight: var(--mediumWeight);
    color: var(--black);
    font-size: var(--regularFontSize);

    border: 1px solid var(--exitBorderBottomGrey);
border-radius: 12px;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: row;

padding-left:12px;
padding-top:7px;
padding-bottom: 7px;
padding-right:12px;
  }

  .detailAppointment__scrollContent_ctaBox{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap:12px;
  }

  .detailAppointment__scrollContent_ctaBox_cancel{
    font-weight: var(--mediumWeight);
    color: var(--red);
    font-size: var(--regularFontSize);
    background-color: var(--white);

    height: 44px;
    width: 160px;

    border-radius: 12px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;

cursor: pointer;
  }

  .detailAppointment__scrollContent_ctaBox_delete{
    font-weight: var(--mediumWeight);
    color: var(--white);
    font-size: var(--regularFontSize);
    background-color: var(--red);

    height: 44px;
    width: 160px;

    border-radius: 12px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;

cursor: pointer;
  }

  .detailAppointment__scrollContent_ctaBox_abort{
    font-weight: var(--mediumWeight);
    color: var(--white);
    font-size: var(--regularFontSize);
    background-color: var(--accentGreen);

    height: 44px;
    width: 160px;

    border-radius: 12px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;

cursor: pointer;
  }