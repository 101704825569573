
.contactPreview{
  padding-top:12px;
  padding-left: 17px;
  padding-right:17px;
  padding-bottom: 13px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  background-color: var(--white);

  border-radius: 12px;
}

.contactPreview__left{

}

.contactPreview__left_imageBox{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.contactPreview__left_imageBox_image{
width: 52px;
height: 52px;
border-radius: 99px;
}

.contactPreview__right{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;

}

.contactPreview__right_text{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  padding-left:12px;
  width: 100%;

}

.contactPreview__right_text_username{

}

.contactPreview__right_text_lastUserMessage{

}

.contactPreview__right_meta{

}

.contactPreview__right_meta_top{
color: var(--white)
}

.contactPreview__right_meta_bottom{

}