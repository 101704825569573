.Fragen {
width:100%;
padding-bottom:120px;

}

.fragen__fixedTopBar{
height:60px;
width: 602px;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: row;

padding-left:11px;
padding-right:11px;
}

.fragen__fixedTopBar_leftColumn{
  width: 100%;

}

.fragen__fixedTopBar_leftColumn_topText{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--XLFontSize);
}

.fragen__fixedTopBar_leftColumn_bottomText{
  font-weight: var(--mediumWeight);
  color: var(--accentBlue);
  font-size: var(--xsFontSize);
}

.fragen__fixedTopBar_middleColumn{

}

.fragen__fixedTopBar_middleColumn_newQuestionButton{
  width: 150px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  padding-left: 22px;
  padding-right:22px;
  padding-top: 7px;
  padding-bottom: 7px;
  
  border-radius: 99px;
  
  background-color: var(--greyBoxOnGrey);
}

.fragen__fixedTopBar_middleColumn_newQuestionButton_icon{

}

.fragen__fixedTopBar_middleColumn_newQuestionButton_text{
  font-weight: var(--regularWeight);
  color: var(--exitGrey);
  font-size: var(--smallFontSize);
  padding-left:5px;
}

.fragen__fixedTopBar_rightColumn{

}

.fragen__fixedTopBar_rightColumn_sortButton{

}

.fragen__fixedTopBar_rightColumn_sortButton_icon{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 70px;
}

.fragen__fixedTopBar_rightColumn_sortButton_text{

}

.fragen__scrollContent{
height: 100%
}

.fragen__scrollContent_element{
background-color: var(--white);
padding: 17px;
border-radius: 12px;
margin-top:11px;
border-bottom: 1px solid var(--exitBorderBottomGrey);
width: 595px;
}

.fragen__scrollContent_element_topBar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.fragen__scrollContent_element_topBar_tags{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap:8px;
}

.fragen__scrollContent_element_topBar_tags_tag{
  background-color: var(--greyBoxOnWhite);
  font-weight: var(--smallWeight);
  color: var(--exitGrey);
  font-size: var(--smallFontSize);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 8px;
}

.fragen__scrollContent_element_topBar_moreButton{

}

.fragen__scrollContent_element_question{
  font-weight: var(--semiboldWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);
  padding-top: 11px;
  padding-bottom: 11px;
}

.fragen__scrollContent_element_answer{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;

  padding-bottom: 15px;

}

.fragen__scrollContent_element_answer_answererProfilepicbox{
margin-right: 13px;
}

.fragen__scrollContent_element_answer_answererProfilepicbox_image{
  width: 31px;
  height: 31px;
  border-radius: 99px;
}

.fragen__scrollContent_element_answer_answerText{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--regularFontSize);
}

.fragen__scrollContent_element_reply{
  border-top: 1px solid var(--exitBorderBottomGrey);
  padding-top: 13px;
  padding-bottom: 15px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.fragen__scrollContent_element_reply_ownImageThumbnailbox{
  margin-right: 13px;
}

.fragen__scrollContent_element_reply_ownImageThumbnailbox_image{
  width: 31px;
  height: 31px;
  border-radius: 99px;
  margin-top: 5px;
}

.fragen__scrollContent_element_replyBox{
  width: 90%;
  height: 40px;

  
  display: flex;
  justify-content: flex-start;
  align-items: center;

} 

.fragen__scrollContent_element_replyBox_input::placeholder{
  color: var(--accentBlue);
}

.fragen__scrollContent_element_replyBox_input{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);

  padding-left: 22px;
  width: 100%;
  height: 100%;
  border: 1px solid var(--exitBorderBottomGrey);
  border-radius: 99px;

}