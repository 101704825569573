.notLoggedInRightSidebar{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;

  margin-left: 30px;
  margin-top: 11px;
  border: 1px solid var(--exitBorderBottomGrey);
  border-radius: 12px;
  padding:22px;
  padding-bottom: 33px;
}

.notLoggedInRightSidebar__topBar{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap:14px;
}

.notLoggedInRightSidebar__middleBar{

}

.notLoggedInRightSidebar__middleBar_topText{

}

.notLoggedInRightSidebar__middleBar_topText_headline{
  color: var(--black);
  font-size: var(--LargeFontSize);
  font-weight: var(--boldWeight);
  text-align: center;

  padding-top: 33px;
}

.notLoggedInRightSidebar__middleBar_topText_description{
  color: var(--black);
  font-size: var(--mediumFontSize);
  font-weight: var(--regularWeight);
  text-align: center;

}

.notLoggedInRightSidebar__middleBar_bottomMarketingPoints{
}

.notLoggedInRightSidebar__middleBar_bottomMarketingPoints_MarketingPoint{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top:22px;

}

.notLoggedInRightSidebar__middleBar_bottomMarketingPoints_MarketingPoint_icon{

}

.notLoggedInRightSidebar__middleBar_bottomMarketingPoints_MarketingPoint_text{
  color: var(--black);
  font-size: var(--mediumFontSize);
  font-weight: var(--regularWeight);
}