.calendar{
  background-color: var(--greyBoxOnWhite);
padding:20px;
border-radius:12px;
margin-top:13px;
}

.calendar__top{
  height:52px;
  display:flex;
  justify-content: space-between;
  flex-direction: row;
  text-align:center;
  align-items:center;
}

.calendar__top_dateString{
  
  font-weight: var(--semiboldWeight);
  color: var(--black);
  font-size: var(--LargeFontSize);

  padding-left: 12px;
}

.calendar__top_changeMonthButtons{
  display:flex;
  justify-content: flex-end;
  flex-direction: row;
  text-align:center;
  align-items:center;
}

.calendar__top_changeMonthButtons_button{
  width:73px;
  height:52px;
  display:flex;
  justify-content: center;
  flex-direction: row;
  text-align:center;
  align-items:center;
  border-radius: 12px;
}

.calendar__top_changeMonthButtons_button:hover{
  background-color: var(--accentBlue);

}


.calendar__row{
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  object-fit:contain;
  display:flex;
  flex-wrap : wrap;
}

.calendar__row_weekdays {
  width:100%;
  height:52px;
  list-style: none;
  flex: 0 0 14.28571428571429%;

  border: 1px solid var(--greyBoxOnWhite);
  font-size: 14px;

  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);

  text-align: center;
  display:flex;
  justify-content: center;
  flex-direction: row;
  text-align:center;
  align-items:center;
}

.calendar__row_days {
  width:100%;
  height:52px;
  list-style: none;
  flex: 0 0 14.28571428571429%;

  border: 1px solid var(--greyBoxOnWhite);
  font-size: 14px;

  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);

  text-align: center;
  display:flex;
  justify-content: center;
  flex-direction: row;
  text-align:center;
  align-items:center;

  border-radius: 12px;
}

.calendar__row_days_active {
  width:100%;
  height:52px;
  list-style: none;
  flex: 0 0 14.28571428571429%;

  border: 1px solid var(--greyBoxOnWhite);
  font-size: 14px;

  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);

  text-align: center;
  display:flex;
  justify-content: center;
  flex-direction: row;
  text-align:center;
  align-items:center;

  border-radius: 12px;
  background-color: var(--accentBlue);
  color: var(--white)

}

.calendar__row_days_disabled {
  width:100%;
  height:52px;
  list-style: none;
  flex: 0 0 14.28571428571429%;

  border: 1px solid var(--greyBoxOnWhite);
  font-size: 14px;

  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);

  text-align: center;
  display:flex;
  justify-content: center;
  flex-direction: row;
  text-align:center;
  align-items:center;

  border-radius: 12px;
  color: var(--exitInfoGrey)

}

.calendar__row_days_empty{
  width:100%;
  height:52px;
  list-style: none;
  flex: 0 0 14.28571428571429%;
}

.calendar__row_days:hover {
  background-color: var(--accentBlue);
color: var(--white)
}



.calendar__bottom{
width:100%;
padding:20px;
}

.calendar__bottom_timebox{
width:100%;
display:flex;
justify-content: flex-start;
flex-direction: row;
text-align:center;
align-items:center;
flex-wrap : wrap;
}

.calendar__bottom_timebox_time{
  height:52px;
  list-style: none;
  width:18.7%;
  display:flex;
  justify-content: center;
  flex-direction: row;
  text-align:center;
  align-items:center;
  background-color: var(--greyBoxOnWhite);
border-radius:12px;
margin-top:10px;
margin-right:1.28571428571429%;
font-weight: var(--semiboldWeight);
color: var(--black);
font-size: var(--mediumFontSize);
}

.calendar__bottom_timebox_time:hover{
  background-color: var(--accentBlue);
  color: var(--white);
}

.calendar__bottom_timebox_time_active{
  background-color: var(--accentBlue);
  color: var(--white);

  height:52px;
  list-style: none;
  width:18.7%;
  display:flex;
  justify-content: center;
  flex-direction: row;
  text-align:center;
  align-items:center;
border-radius:12px;
margin-top:10px;
margin-right:1.28571428571429%;
font-weight: var(--semiboldWeight);
font-size: var(--mediumFontSize);
}

