.booking{
    padding-bottom:120px;
  }
  
  .booking__fixedTopBar{
    min-height:60px;
    padding-bottom:5px;
    padding-top:5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 602px;
    background-color: var(--lightGrey);
    padding-left: 22px;
  
   /* position: fixed; */
  padding-right:11px;
  }
  
  .booking__fixedTopBar_left{
  width:40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-right:2px;
  }
  
  .booking__fixedTopBar_middle{
    font-weight: var(--semiboldWeight);
  color: var(--black);
  font-size: var(--LargeFontSize);
line-height: 1.2em;
text-align: center;
margin-top:3px;
  }
  
  .booking__fixedTopBar_right{
    background-color: var(--white);
width:100%;
padding-top:9px;
padding-bottom: 9px;
padding-left: 14px;
padding-right:14px;
border-radius: 12px;
margin-bottom:11px;
  }

  .booking__fixedTopBar_right_profileBox{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }

  .booking__fixedTopBar_right_profileBox_left{

  }

  .booking__fixedTopBar_right_profileBox_left_imageBox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .booking__fixedTopBar_right_profileBox_left_imagebox_image{
    width: 61px;
    height:61px;
    border-radius: 99px;
  }
  
  .booking__fixedTopBar_right_profileBox_right{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left:15px;
  }

.booking__fixedTopBar_right_profileBox_right_topText{
    font-weight: var(--semiboldWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);
}

.booking__fixedTopBar_right_profileBox_right_bottomText{
    font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--regularFontSize);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap:3px;
}

.booking__fixedTopBar_right_callBtnBox{
  height:60px
}

.booking__fixedTopBar_right_callBtnBox_callBtn{
  height:50px;
  background-color: var(--greyBoxOnWhite);
  margin-top:11px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  border-radius: 12px;

  width:100%;
  max-width:160px;

  font-weight: var(--semiboldWeight);
  font-size: var(--regularFontSize);

  padding-left:15px;
}
  
  .booking__scrollContent{
  /*  padding-top: 80px; */
  }
  
  .booking__scrollContent_elementBox{
    background-color: var(--white);
    width:100%;
    padding-top:18px;
    padding-bottom: 18px;
    padding-left: 16px;
    padding-right:16px;
    border-radius: 12px;
    margin-bottom:11px;
  }
  
  .booking__scrollContent_elementBox_title{
    font-weight: var(--mediumWeight);
    color: var(--accentBlue);
    font-size: var(--LargeFontSize);
  }
  
  .booking__scrollContent_elementBox_content{
    
  }

  .booking__scrollContent_elementBox_content_leistungComponent{
    min-height: 55px;
    width: auto;
    padding:14px;
    background-color: var(--greyBoxOnWhite);
    margin-top:12px;
    border-radius:12px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: var(--black);

  }

  .booking__scrollContent_elementBox_content_leistungComponent_active{
    min-height: 55px;
    width: auto;
    padding:14px;
    background-color: var(--accentBlue);
    margin-top:12px;
    border-radius:12px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: var(--white);

  }

  .booking__scrollContent_elementBox_content_leistungComponent:hover{
    background-color: var(--accentBlue);
    color: var(--white);

  }

  .booking__scrollContent_elementBox_content_leistungComponent_top{
    font-weight: var(--semiboldWeight);
    font-size: var(--regularFontSize);
  }

  .booking__scrollContent_elementBox_content_leistungComponent_bottom{
    font-weight: var(--regularWeight);
    font-size: var(--smallFontSize);
  }

  .booking__scrollContent_CTAbox{
width: 100%;
max-width:602px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
height: auto;

padding-top:5px;
padding-bottom:5px;

position:fixed;
bottom:0;

background-color: var(--lightGrey);
  }

  .booking__scrollContent_CTAbox_CTA{
    padding-top:14px;
    padding-bottom: 14px;
    padding-left: 20px;
    padding-right:20px;
    border-radius: 12px;
    background-color: var(--accentBlue);
    font-weight: var(--mediumWeight);
    color: var(--white);
    font-size: var(--mediumFontSize);
   max-width:90%;

   
  }

    .booking__scrollContent_CTAbox_disabled{
      width: 100%;
      max-width:602px;

display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
height: auto;

padding-top:5px;
padding-bottom:5px;

position:fixed;
bottom:0;
background-color: var(--lightGrey);
  }

  .booking__scrollContent_CTAbox_disabled_CTA{
    padding-top:14px;
    padding-bottom: 14px;
    padding-left: 60px;
    padding-right:60px;
    border-radius: 12px;
    background-color: grey;
    font-weight: var(--mediumWeight);
    color: var(--white);
    font-size: var(--mediumFontSize);
  }
.booking__scrollContent_privacybox{
width:100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
margin-top:5px;

}

  .booking__scrollContent_privacybox_privacy{
    font-size: var(--xxsFontSize);
    max-width:550px;
    text-align:left;

  }