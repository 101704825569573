.article{
  padding-bottom:120px;
}

.article__fixedTopBar{
  height:60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 602px;
  background-color: var(--lightGrey);
  padding-left: 22px;

  position: fixed;

}

.article__fixedTopBar_text{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--XLFontSize);
}

.article__scrollContent{
  padding-top: 60px;


}

.article__scrollContent_top{
padding-top: 11px;
  padding-bottom: 11px;
padding-left: 22px;
padding-right:22px;
}

.article__scrollContent_top_tags{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 8px;  flex-wrap: wrap;

}

.article__scrollContent_top_headline{

  padding-top: 22px;

  font-weight: var(--semiboldWeight);
  color: var(--black);
  font-size: var(--XLFontSize);
}

.article__scrollContent_top_meta{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--regularFontSize);
}

.article__scrollContent_answers{

}

.article__scrollContent_answers_answer{
  flex-wrap: wrap;

}

.article__fixedBottomBar{
  position: fixed;
  bottom: 0;
  width: 602px;
  height:90px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  background-color: var(--lightGrey);
}

.article__fixedBottomBar_answerInputbox{

  width: 100%;
  height: 40px; 
  background-color: var(--white);
  border-radius: 99px;
  border: 2px solid var(--exitBorderBottomGrey);

  display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
}

.article__fixedBottomBar_answerInputbox_answerInput::placeholder{
  color: var(--accentBlue);
font-size: var(--mediumFontSize);
}

.article__fixedBottomBar_answerInputbox_answerInput{
border:none;
border-radius: 99px;
width: 100%;
padding-left: 22px;

font-weight: var(--mediumWeight);
color: var(--black);
font-size: var(--mediumFontSize);
}