.info{
padding-left:9px;
padding-right:9px;
}

.info_imagePreviewbox{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.info_imagePreviewbox_imagePreview{
  width: 100px; height: 100px;
  border-radius: 99px;
  border: 1px solid var(--exitBorderBottomGrey);

}

.info_imagePreviewbox_description{
background-color: var(--accentBlue);
color:var(--white);
margin-left:11px;
padding-left:15px;
padding-right:15px;
padding-top:11px;
padding-bottom: 11px;
border-radius: 99px;
}

.info_replyBox_addImage_input{
  display:none;
}

.info_submitButtonBox{
  display: flex;
  justify-content:flex-end;
  align-items: center;
  flex-direction: row;
}

.info_changeName{
  border: 1px solid var(--exitBorderBottomGrey);
  border-radius: 20px;
width: 400px;
min-height: 40px;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;

margin-top:30px;
margin-bottom:30px;
}

.info_changeName_input{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);

  padding-left: 18px;
  width: 100%;
  min-height: 40px;

  overflow: hidden;
  resize: none;
  white-space: pre-wrap;
  word-break: break-word;
  border-radius: 20px;
  border: 1px solid var(--exitBorderBottomGrey);

}


.info_submitButtonBox_submitButton{
background-color: var(--accentBlue);
color: var(--white);
height:40px;
width:300px;
padding-left:20px;
padding-right:20px;
border-radius: 99px;

display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: row;
}

.info_submitButtonBox_submitButton_disabled{
  background-color: var(--exitBorderBottomGrey);
  color: var(--white);
  height:40px;
  width:300px;
  padding-left:20px;
  padding-right:20px;
  border-radius: 99px;
  
  display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: row;
}