.searchResultExpert{
width:100%;
background-color: var(--white);

display:flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
height:auto;
padding-bottom:10px;
padding-top:10px;
border-radius:12px;
border-bottom: 1px solid var(--exitBorderBottomGrey);
margin-top:3px;
}

.searchResultExpert__left{

}

.searchResultExpert__left_imagebox{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:90px;
}

.searchResultExpert__left_imagebox_image{
width:60px;
height:60px;
border-radius: 99px;
}

.searchResultExpert__right{

}

.searchResultExpert__right_top{
font-size: var(--mediumFontSize);
font-weight: var(--semiboldWeight);
color: var(--black);
}

.searchResultExpert__right_bottom{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap:8px;
}

.searchResultExpert__right_bottom_text{
    font-size: var(--regularFontSize);
    font-weight: var(--semiboldWeight);
    color: var(--accentBlue);
}

.searchResultExpert__right_bottom_tag{
background-color: var(--greyBoxOnGrey);
padding-left:11px;
padding-right:11px;
padding-top:1px;
padding-bottom: 1px;
border-radius: 8px;
}