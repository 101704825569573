.chat {
width:602px;
padding-bottom:120px;
height:100%;
padding-left:22px;
}

.chat__fixedTopBar{
height:60px;
width: 602px;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: row;
}

.chat__fixedTopBar_leftColumn{
  width: 100%;

}

.chat__fixedTopBar_leftColumn_topText{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--XLFontSize);
}

.chat__fixedTopBar_leftColumn_bottomText{
  font-weight: var(--mediumWeight);
  color: var(--accentBlue);
  font-size: var(--xsFontSize);
}

.chat__fixedTopBar_middleColumn{

}

.chat__fixedTopBar_middleColumn_newQuestionButton{
  width: 150px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  padding-left: 22px;
  padding-right:22px;
  padding-top: 7px;
  padding-bottom: 7px;
  
  border-radius: 99px;
  
  background-color: var(--greyBoxOnGrey);
}

.chat__fixedTopBar_middleColumn_newQuestionButton_icon{

}

.chat__fixedTopBar_middleColumn_newQuestionButton_text{
  font-weight: var(--regularWeight);
  color: var(--exitGrey);
  font-size: var(--smallFontSize);
  padding-left:5px;
}

.chat__fixedTopBar_rightColumn{

}

.chat__fixedTopBar_rightColumn_sortButton{

}

.chat__fixedTopBar_rightColumn_sortButton_icon{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 70px;
}

.chat__fixedTopBar_rightColumn_sortButton_text{

}

.chat__scrollContent{
height: 100%;
width: 100%;

overflow-y: scroll;
overflow-x: hidden;
position: fixed;
max-width: 602px;

padding-bottom: 100px;
}

.chat__scrollContent_contactMessageBox{
width:100%;
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: row;
}

.chat__scrollContent_contactMessageBox_contactMessage{
  background-color: var(--greyBoxOnGrey);
min-height: 42px;
padding:12px;
border-radius: 22px;
max-width: calc(100% - 100px);

}

.chat__scrollContent_ownMessageBox{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.chat__scrollContent_ownMessageBox_ownMessage{
  background-color: var(--accentBlue);
  color: var(--white);
min-height: 42px;
padding:12px;
border-radius: 22px;

max-width: calc(100% - 100px);
}

.chat__scrollContent_infoMessageBox{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.chat__scrollContent_infoMessageBox_infoMessage{
  border: 2px solid var(--exitBorderBottomGrey);
  color: var(--accentBlue);
  min-height: 42px;
  padding:12px;
  border-radius: 22px;
  
  max-width: calc(100% - 100px);
}

.chat__fixedBottomBar{
  width: 100%;
  max-width:602px;
  height: 80px;
  bottom:0;
  position:fixed;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-left: 12px;
  padding-right: 12px;

  background-color: var(--lightGrey);

}

.chat__fixedBottomBar_inputBox{
  width:100%;
  height:50px;
  background-color: var(--white);

  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;

  border-radius: 26px;

}

.chat__fixedBottomBar_inputBox_input::placeholder{
  color: var(--accentBlue);
}

.chat__fixedBottomBar_inputBox_input{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);
background-color: var(--white);

  width: 100%;
  overflow: hidden;
  resize: none;
  white-space: pre-wrap;
  word-break: break-word;
  border-radius: 26px;

  padding-left:20px;
  border: 1px solid var(--exitBorderBottomGrey);
  padding-top:12px;
  padding-bottom:12px;

}

.chat__fixedBottomBar_inputBox_input[contenteditable]:empty::before{
  content: "Schreibe eine Nachricht";
  color: var(--accentBlue);
  cursor: text;
}

.chat__scrollContent_element_submitButtonBox{
  width: 100px;
  height:100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  margin-left:8px;
  }
  
  .chat__scrollContent_element_submitButtonBox_submitButton{
    display:flex;
    justify-content: center;
  align-items: center;
  flex-direction: row;
    width:100px;
    height:50px;
    background-color: var(--accentBlue);
    border-radius: 99px;
    color: var(--white);
  
    cursor: pointer;
  }
  
  .chat__scrollContent_element_submitButtonBox_submitButton_disabled{
    display:flex;
    justify-content: center;
  align-items: center;
  flex-direction: row;
    width:100px;
    height:50px;
    background-color: var(--exitBorderBottomGrey);
    border-radius: 99px;
    color: var(--white);
  
    cursor:not-allowed;
  }