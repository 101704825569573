
/* FONTS */
/* anek-tamil-100 - latin_tamil */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/anek-tamil-v4-latin_tamil-100.eot'); /* IE9 Compat Modes */
  src: url('../fonts/anek-tamil-v4-latin_tamil-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/anek-tamil-v4-latin_tamil-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-100.woff') format('woff'), /* Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/anek-tamil-v4-latin_tamil-100.svg#AnekTamil') format('svg'); /* Legacy iOS */
}
/* anek-tamil-200 - latin_tamil */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/anek-tamil-v4-latin_tamil-200.eot'); /* IE9 Compat Modes */
  src: url('../fonts/anek-tamil-v4-latin_tamil-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/anek-tamil-v4-latin_tamil-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-200.woff') format('woff'), /* Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/anek-tamil-v4-latin_tamil-200.svg#AnekTamil') format('svg'); /* Legacy iOS */
}
/* anek-tamil-300 - latin_tamil */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/anek-tamil-v4-latin_tamil-300.eot'); /* IE9 Compat Modes */
  src: url('../fonts/anek-tamil-v4-latin_tamil-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/anek-tamil-v4-latin_tamil-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/anek-tamil-v4-latin_tamil-300.svg#AnekTamil') format('svg'); /* Legacy iOS */
}
/* anek-tamil-regular - latin_tamil */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/anek-tamil-v4-latin_tamil-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/anek-tamil-v4-latin_tamil-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/anek-tamil-v4-latin_tamil-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/anek-tamil-v4-latin_tamil-regular.svg#AnekTamil') format('svg'); /* Legacy iOS */
}
/* anek-tamil-500 - latin_tamil */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/anek-tamil-v4-latin_tamil-500.eot'); /* IE9 Compat Modes */
  src: url('../fonts/anek-tamil-v4-latin_tamil-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/anek-tamil-v4-latin_tamil-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/anek-tamil-v4-latin_tamil-500.svg#AnekTamil') format('svg'); /* Legacy iOS */
}
/* anek-tamil-600 - latin_tamil */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/anek-tamil-v4-latin_tamil-600.eot'); /* IE9 Compat Modes */
  src: url('../fonts/anek-tamil-v4-latin_tamil-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/anek-tamil-v4-latin_tamil-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/anek-tamil-v4-latin_tamil-600.svg#AnekTamil') format('svg'); /* Legacy iOS */
}
/* anek-tamil-700 - latin_tamil */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/anek-tamil-v4-latin_tamil-700.eot'); /* IE9 Compat Modes */
  src: url('../fonts/anek-tamil-v4-latin_tamil-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/anek-tamil-v4-latin_tamil-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/anek-tamil-v4-latin_tamil-700.svg#AnekTamil') format('svg'); /* Legacy iOS */
}
/* anek-tamil-800 - latin_tamil */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Anek Tamil';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/anek-tamil-v4-latin_tamil-800.eot'); /* IE9 Compat Modes */
  src: url('../fonts/anek-tamil-v4-latin_tamil-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/anek-tamil-v4-latin_tamil-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/anek-tamil-v4-latin_tamil-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/anek-tamil-v4-latin_tamil-800.svg#AnekTamil') format('svg'); /* Legacy iOS */
}

/* Skalierungsfaktoren */

/* alles unter 692 muss auf mobile seite redirecten? */
@media only screen and (min-width: 0px) and (max-width: 691px) {
.leftColumn{
display: none;
}

.calenderConfig__scrollContent{
  position: relative !important;
}

.topNavBarOnlyOnMobile{
  display: flex;
}

.home__fixedPageRight, .contacts__fixedPageRight, .sidebar__middle_navigationLinks_link_text, .sidebar__bottom{
  display:none;
}
.app__routingPages, .home__scrollPageLeft, .contacts__scrollPageLeft, .fragen__fixedTopBar, .search__fixedTopBar, .register__fixedTopBar, .booking__fixedTopBar{
  width: 100% !important;
}

}

/* Bildschirmbreiten */
@media only screen and (min-width: 692px) and (max-width: 759px) {
  :root{
    --leftSidebarDecreaseNumber: 214px;
  }
  .topNavBarOnlyOnMobile{
    display: none;
  }
  .home__fixedPageRight, .contacts__fixedPageRight, .sidebar__middle_navigationLinks_link_text, .sidebar__bottom{
    display:none;
  }
  .app__routingPages, .booking__scrollContent_CTAbox, .booking__scrollContent_CTAbox_disable{
    width: 602px !important;
  }
  .sidebar__footer_links{
    font-size: var(--xxsFontSize) !important;
  }
  #logoLong{
    display: none;
  }
  .cta__small{
    border-radius:12px!important;
    word-wrap: break-word;
    font-size: var(--xxsFontSize) !important;
    letter-spacing: var(--halfTighterLetterSpacing);
  }
  .cta__outlinedSmall{
    border-radius:12px!important;
    word-wrap: break-word;
    font-size: var(--xxsFontSize) !important;
    letter-spacing: var(--halfTighterLetterSpacing);
  }
}

/* Bildschirmbreiten */
@media only screen and (min-width: 760px) and (max-width: 1049px) {
  :root{
    --leftSidebarDecreaseNumber: 130px;
  }
  .topNavBarOnlyOnMobile{
    display: none;
  }
  .home__fixedPageRight, .contacts__fixedPageRight{
    display:none;
  }
  .app__routingPages , .booking__scrollContent_CTAbox, .booking__scrollContent_CTAbox_disable{
    width: 602px !important;
  }
  .expertsSidebar__bottom_similarExperts_expert_imagebox{
    padding-right: 0px !important;
  }
  .expertsSidebar__bottom_similarExperts_expert{
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    flex-direction: column !important;
    text-align: center !important;
  }
  .expertsSidebar__bottom_similarExperts_expert_description_expertFor{
    font-size: var(--smallFontSize);
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    word-wrap: break-word;
  }
  .expertsSidebar__bottom_similarExperts_expert_description_expertFor_tag{
    margin-left: 0px !important;
  }
  #logoShort{
    display: none;
  }
}

/* Tablet Vertical*/
@media only screen and (min-width: 1050px) and (max-width: 1150px) {
  :root{
    --leftSidebarDecreaseNumber: 214px;
  }
  .topNavBarOnlyOnMobile{
    display: none;
  }
  .booking__scrollContent_CTAbox, .booking__scrollContent_CTAbox_disable{
    width: 602px !important;
}
  .home__fixedPageRight ,.contacts__fixedPageRight{
    display:block;
  }
  .sidebar__middle_navigationLinks_link_text, .sidebar__bottom{
    display:none;
  }
  .sidebar__footer_links{
    font-size: var(--xxsFontSize) !important;
  }
  #logoLong{
    display: none;
  }
  .sidebar__logins{
    display:none !important;
  }
}

/* Tablet Landscape*/
@media only screen and (min-width: 1150px) and (max-width: 1280px) {
  :root{
    --leftSidebarDecreaseNumber: 130px;
  }
  .topNavBarOnlyOnMobile{
    display: none;
  }
  .booking__scrollContent_CTAbox, .booking__scrollContent_CTAbox_disable{
    width: 602px !important;
}
  .home__fixedPageRight, .contacts__fixedPageRight, .sidebar__middle_navigationLinks_link_text, .sidebar__bottom{
    display:block;
  }
  .expertsSidebar__bottom_similarExperts_expert_imagebox{
    padding-right: 0px !important;
  }
  .expertsSidebar__bottom_similarExperts_expert{
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    flex-direction: column !important;
    text-align: center !important;
  }
  .expertsSidebar__bottom_similarExperts_expert_description_expertFor{
    font-size: var(--smallFontSize);
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    word-wrap: break-word;
  }
  .expertsSidebar__bottom_similarExperts_expert_description_expertFor_tag{
    margin-left: 0px !important;
  }
  #logoShort{
    display: none;
  }
  .sidebar__logins{
    display:none !important;
  }
}

/* Desktop and Larger */
@media only screen and (min-width: 1281px) {
  :root{
    --leftSidebarDecreaseNumber: 0px;
  }
  .topNavBarOnlyOnMobile{
    display: none;
  }
  .home__fixedPageRight, .contacts__fixedPageRight, .sidebar__middle_navigationLinks_link_text, .sidebar__bottom{
    display:block;
  }
  #logoShort{
    display: none;
  }
  .sidebar__logins{
    display:none !important;
  }
}


*,
*:before,
*:after {
  margin: 0pt;
  padding: 0pt;
  box-sizing: border-box;
  font-family: var(--firstFont);

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

.activeLink{
  color: var(--accentBlue);
}

.bold {
  font-weight: var(--boldWeight)
  }

  .semibold {
    font-weight: var(--semiboldWeight)
    }

    .medium {
      font-weight: var(--mediumWeight)
      }

.content-pad {
  width: 100%;
}


/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(214, 214, 214);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(199, 199, 199);
}

:focus-visible {
  outline: 0px solid white;
}

input:focus::placeholder {
  color: lightgrey;
}

input::placeholder {
  color: black;
}

/** 
 * The default focus style is likely provided by Bootstrap or the browser
 * but here we override everything else with a visually appealing cross-
 * browser solution that works well on all focusable page elements
 * including buttons, links, inputs, textareas, and selects.
 */
*:focus {
  outline: 0 !important;
}

/**
   * Undo the above focused button styles when the element received focus
   * via mouse click or touch, but not keyboard navigation.
   */
*:focus:not(:focus-visible) {
  outline: 0 !important;
  box-shadow: none !important;
}

:root {
  /* FontFamilys - Schriftartvariablen */
  --firstFont: "Anek Tamil", normal;
  --secondFont: "Inter-Medium", "Inter", sans-serif;

  /* FontSizes - Schriftgroessenvariablen */
  --xxxsFontSize: 8px;
  --xxsFontSize: 10px;
  --xsFontSize: 12px;
  --smallFontSize: 14px;
  --regularFontSize: 16px;
  --mediumFontSize: 18px;
  --LargeFontSize: 20px;
  --XLFontSize: 22px;
  --XXLFontSize: 24px;


  /* FontWeights - Schriftdicke */
  --smallWeight: 300;
  --regularWeight: 400;
  --mediumWeight: 500;
  --semiboldWeight: 600;
  --boldWeight: 700;
  --extraBoldWeight: 800;

  /* letterSpacings - Schriftabstand */
  --widerLetterSpacing: 1.2px;
  --halfWiderLetterSpacing: 0.6px;
  --noLetterSpacing: 0px;
  --halfTighterLetterSpacing: -0.6px;
  --tighterLetterSpacing: -1.2px;


  /* colors - Farbvariablen */
  --white: #ffffff;
  --black: #000000;
  --darkGrey: #222222;
  --lightGrey: #F0F2F5;
  --leefGreen: #00ad4b;
  --greenGradient: linear-gradient(267deg, #00ff6f 0%, #007a35 100%);
  --redGradient: linear-gradient(267deg, #ff0000 0%, #7a0000 100%);
  --darkWhite: #dbdbdb;
  --accentBlue: #1877f2;  
  --accentGreen: #239f33;
  --greyBoxOnGrey: #e4e6eb;
  --greyBoxOnWhite: #f5f5f5;
  --hidingGrey: #b5b5b5;
  --exitGrey: #575757;
  --exitBorderBottomGrey: #dddddd;
  --exitInfoGrey: #a3a3a3;
  --red: #cf0606;

  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom); /* THIS ONE GETS US THE HOME BAR HEIGHT: getComputedStyle(document.documentElement).getPropertyValue("--sab")*/
  --sal: env(safe-area-inset-left);
}

body {
  background-color: var(--lightGrey);
}

pre {
  word-break: break-all; 
  word-wrap: break-word;
  white-space: pre;
  white-space: -moz-pre-wrap; 
  white-space: pre-wrap;
  white-space: pre\9; 
  }

.app{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  height: 100%;
}

.app__fixedSidebar {
  width: calc(282px - var(--leftSidebarDecreaseNumber));
}

.app__routingPages{
  width:1001px;
  height:100%;
}

.addHeightForIphoneNodge{
  height:34px;
}

