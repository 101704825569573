.lightroom{
  height:calc(98vh - 200px);

}

.lightroom__fixedTopBar{
  height:60px;
  width: 100%;
  background-color: var(--lightGrey);
  padding-left: 22px;
  position: fixed;
  padding-right: 22px;
  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.lightroom__fixedTopBar_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  max-width:558px;
width:100%;
}

.lightroom__fixedTopBar_text{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--XLFontSize);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.lightroom__fixedTopBar_cancelIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.lightroom__fixedImageBox{
  width:100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top:130px;

}

.lightroom__fixedImageBox_image{
  width:100%;
  height:auto;
}

.lightroom__fixedImageBox_arrows{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width:100%;
  gap:8px;
}

.lightroom__fixedImageBox_arrows_arrowLeft, .lightroom__fixedImageBox_arrows_arrowRight{
  width:40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom:10px;
  background-color: var(--exitBorderBottomGrey);
  border-radius:12px;
  margin-top:15px;
}
