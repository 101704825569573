.postsingle{
background-color: var(--white);
border-radius: 12px;
margin-top:11px;
border-bottom: 1px solid var(--exitBorderBottomGrey);
width: 100%;
}

.postSingleWithOwnAnswer {
border-radius: 12px;
margin-top:5px;
border-bottom: 1px solid var(--exitBorderBottomGrey);
width: 100%;
background-color: var(--white);
padding-left:11px;
padding-right:11px;
padding-top:4px;
padding-bottom:4px;
}