.cta{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  width: 292px;
  height: 51px;
  background-color: var(--accentBlue);
  color: var(--white);
  border-radius: 99px;
  font-size: var(--regularFontSize);
  font-weight: var(--mediumWeight);
}

.cta__disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  width: 292px;
  height: 51px;
  background-color: var(--hidingGrey);
  color: var(--white);
  border-radius: 99px;
  font-size: var(--regularFontSize);
  font-weight: var(--mediumWeight);
}

.cta__outlined {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  width: 292px;
  height: 51px;
  border: 2px solid var(--accentBlue);
  color: var(--accentBlue);
  border-radius: 99px;
  font-size: var(--regularFontSize);
  font-weight: var(--mediumWeight);
}
.cta__small{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  width: 100%;
  height: 51px;
  background-color: var(--accentBlue);
  color: var(--white);
  border-radius: 99px;
  font-size: var(--regularFontSize);
  font-weight: var(--mediumWeight);
}

.cta__disabledSmall {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  width: 100%;
  height: 51px;
  background-color: var(--hidingGrey);
  color: var(--white);
  border-radius: 99px;
  font-size: var(--regularFontSize);
  font-weight: var(--mediumWeight);
}

.cta__outlinedSmall {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  width: 100%;
  height: 51px;
  border: 2px solid var(--accentBlue);
  color: var(--accentBlue);
  border-radius: 99px;
  font-size: var(--regularFontSize);
  font-weight: var(--mediumWeight);
}