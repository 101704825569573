.profile {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  padding-bottom:80px;
}

.profile__thumbnailBox{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  padding-top: 15px;
  padding-bottom: 15px;

  padding-left:11px;
  padding-right:11px;
}

.profile__thumbnailBox_imagebox{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.profile__thumbnailBox_imagebox_image{
  width: 61px;
  height: 61px;
  border-radius: 99px;
}

.profile__thumbnailBox_nameText{
  padding-left: 17px;

  font-weight: var(--semiboldWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);
}

.profile__thumbnailBox_shareButtonBox{
width: 100%;

display: flex;
justify-content: flex-end;
align-items: center;
flex-direction: row;
}

.profile__thumbnailBox_shareButtonBox_shareButton{
  width: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  padding-left: 15px;
  padding-right:15px;
  padding-top: 7px;
  padding-bottom: 7px;
  
  border-radius: 99px;
  
  background-color: var(--greyBoxOnGrey);
  cursor: pointer;

}

.profile__thumbnailBox_shareButtonBox_shareButton_icon{

}

.profile__thumbnailBox_shareButtonBox_shareButton_text{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--regularFontSize);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.profile__thumbnailBox_contactButtonBox{
  width: 100%;
  
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  }
  
  .profile__thumbnailBox_contactButtonBox_contactButton{
    width: auto;
  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  
    padding-left: 22px;
    padding-right:22px;
    padding-top: 11px;
    padding-bottom: 11px;
    
    border-radius: 99px;
    text-align: center;
    
    background-color: var(--accentBlue);

    cursor: pointer;
  }
  
  .profile__thumbnailBox_contactButtonBox_contactButton_icon{
  
  }
  
  .profile__thumbnailBox_contactButtonBox_contactButton_text{
    font-weight: var(--mediumWeight);
    color: var(--white);
    font-size: var(--regularFontSize);
  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

.profile__imageCollection{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap:nowrap;
gap:4px;
  height: auto;
  width: calc(100% - 11px);

  overflow-x: scroll;
  overflow-y:hidden;
  margin-left:11px;
}

.profile__imageCollection_imagebox{
  width: 100px !important;
  height: 100px !important;
  height: 125px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.profile__imageCollection_imagebox_image{
  width: 100px;
  height: 100px;
  border-radius: 4px;
}

.profile__expertFor{
  padding-top: 7px;
  width:100%;
  padding-left:11px;
  padding-right:11px;
}

.profile__expertFor_text{
  font-weight: var(--mediumWeight);
  color: var(--accentBlue);
  font-size: var(--LargeFontSize);
  letter-spacing: var(--noLetterSpacing);

  padding-top: 20px;
  padding-bottom: 11px;
}

.profile__expertFor_tags{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap:8px;
  width:100%;
  flex-wrap: wrap;
}

.profile__expertFor_tags_tag{
  background-color: var(--greyBoxOnGrey);
  font-weight: var(--semiboldWeight);
  color: var(--black);
  font-size: var(--regularFontSize);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 8px;
}

.profile__userAnswers{
padding-top: 40px;
width:100%;
padding-left:11px;
padding-right:11px;
}

.profile__userAnswers_topBar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  width: 100%;

  border-bottom: 1px solid var(--exitBorderBottomGrey);
  padding-bottom: 11px;
}

.profile__userAnswers_topBar_text{
  font-weight: var(--mediumWeight);
  color: var(--accentBlue);
  font-size: var(--LargeFontSize);
  letter-spacing: var(--noLetterSpacing);


}

.profile__userAnswers_topBar_sortButton{

}

.profile__userAnswers_topBar_sortButton_icon{

}

.profile__userAnswers_element{

}

.profile__userAnswers_tags{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap:8px;
  flex-wrap: wrap;

  padding-bottom: 12px;
  padding-top: 12px;
}

.profile__userAnswers_tags_tag{
  background-color: var(--greyBoxOnGrey);
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--regularFontSize);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
}

.profile__userAnswers_question{
  font-weight: var(--semiboldWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);
  padding-bottom: 11px;
}

.profile__userAnswers_answer{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;

  padding-bottom: 15px;
}

.profile__userAnswers_answer_answererProfilepicbox{
  margin-right: 13px;

}

.profile__userAnswers_answer_answererProfilepicbox_img{
  width: 38px;
  height: 38px;
  border-radius: 99px;
}

.profile__userAnswers_answer_answerText{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--regularFontSize);
}

.profile__userAnswers_moreButton{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--regularFontSize);
  padding-left: 51px;
}

.profile__userAnswers_helpfulFortext{
  font-weight: var(--regularWeight);
  color: var(--exitInfoGrey);
  font-size: var(--regularFontSize);
  padding-left: 51px;
}