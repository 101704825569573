.expert{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  padding-top:5px;
}

.expert_imagebox{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-right:12px;
}

.expert_imagebox_image{
  width:61px;
  height:61px;
  border-radius: 99px;
}

.expert_description{

}

.expert_description_name{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--regularFontSize);
}

.expert_description_expertFor{
  font-weight: var(--regularWeight);
  color: var(--accentBlue);
  font-size: var(--regularFontSize);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.expert_description_expertFor_text{

}

.expert_description_expertFor_tag{
margin-left:5px;
margin-top:0px;

padding-left: 11px;
padding-right:11px;
padding-top: 2px;
padding-bottom: 2px;

border-radius: 8px;

background-color: var(--greyBoxOnGrey);
font-weight: var(--mediumWeight);
color: var(--black);
font-size: var(--smallFontSize);
}