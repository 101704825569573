.newQuestion{
  padding-bottom:120px;
height:1500px;
}

.newQuestion__fixedTopBar{
  height:60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width:100%;
  background-color: var(--lightGrey);
  padding-left: 22px;
}

.newQuestion__fixedTopBar_text{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--XLFontSize);
}

.newQuestion__scrollContent_questionBox, .newQuestion__scrollContent_keywordBox{
  background-color: var(--white);
padding: 22px;
border-radius: 12px;
margin-bottom: 11px;
}

.newQuestion__scrollContent_questionBox_questionText, .newQuestion__scrollContent_keywordBox_questionText{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--LargeFontSize);
}

.newQuestion__scrollContent_questionBox_questionDescription, .newQuestion__scrollContent_keywordBox_questionDescription{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--RegularSize);
}

.newQuestion__scrollContent_keywordBox_questionDescription_chosenTags{
  display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: column;
padding-top:20px;
padding-bottom:20px;
gap:11px;
}

.newQuestion__scrollContent_keywordBox_questionDescription_chosenTags_wrapper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.newQuestion__scrollContent_keywordBox_questionDescription_chosenTags_number{
width:20px;
font-weight: var(--mediumWeight);
color: var(--black);
font-size: var(--LargeFontSize);
}

.newQuestion__scrollContent_keywordBox_questionDescription_chosenTags_delete{
  width:40px;
  display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
}

.newQuestion__scrollContent_questionBox_questionInputbox{
  border: 1px solid var(--exitBorderBottomGrey);
  border-radius: 20px;
width: 100%;
min-height: 80px;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: row;

}


.newQuestion__scrollContent_questionBox_questionInputbox_input[contenteditable]:empty::before{
  content: "Frage eingeben";
  cursor: text;
  font-weight: var(--mediumWeight);
  color: var(--accentBlue);
  font-size: var(--mediumFontSize);
}


.newQuestion__scrollContent_questionBox_questionInputbox_input{
  font-weight: var(--semiboldWeight);
  color: var(--black);
  font-size: var(--XLFontSize);

  padding-left: 15px;
  padding-right: 11px;
  padding-top:6px;
  width: 100%;
  height:100%;
  min-height:80px;

  overflow: hidden;
  resize: none;
  white-space: pre-wrap;
  word-break: break-word;

  
}

.newQuestion__scrollContent_keywordBox_section{
width: 100%;
margin-top: 22px;
}

.newQuestion__scrollContent_keywordBox_section_title{
  font-weight: var(--boldWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);
}

.newQuestion__scrollContent_keywordBox_section_searchInputbox{
  width: 100%;
  border: 2px solid var(--exitBorderBottomGrey);
  border-radius: 99px;
  margin-top: 22px;

}

.newQuestion__scrollContent_keywordBox_section_searchInputbox_input::placeholder{
  color: var(--accentBlue);
}

.newQuestion__scrollContent_keywordBox_section_searchInputbox_input{
  width: 100%;
border:none;
height: 40px;
border-radius: 99px;
padding-left: 22px;

font-weight: var(--mediumWeight);
color: var(--black);
font-size: var(--mediumFontSize);
}

.newQuestion__scrollContent_keywordBox_section_tags{
  width: 100%;
  padding: 14px;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 14px;
  flex-wrap:wrap;

}

.newQuestion__scollContent_CTAbox{
  width: 100%;
  display:flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;

  margin-top: 22px;
}