.register{
  padding-bottom:120px;

}

.register__fixedTopBar{
  height:60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 602px;
  background-color: var(--lightGrey);
  padding-left: 22px;
}

.register__fixedTopBar_text{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--XLFontSize);
  width:100%;
}

.register__fixedTopBar_cancel{
width:40px;
text-align: right;
}

.register__scrollContent_nameBox, .register__scrollContent_interestsBox{
  background-color: var(--white);
padding: 22px;
border-radius: 12px;
margin-bottom: 11px;
}

.register__scrollContent_nameBox_nameText, .register__scrollContent_interestsBox_nameText{
  font-weight: var(--mediumWeight);
  color: var(--black);
  font-size: var(--LargeFontSize);
}

.register__scrollContent_nameBox_nameDescription, .register__scrollContent_interestsBox_nameDescription{
  font-weight: var(--regularWeight);
  color: var(--black);
  font-size: var(--RegularSize);
}

.register__scrollContent_nameBox_nameInputbox{
  width: 100%;
  border: 2px solid var(--exitBorderBottomGrey);
  border-radius: 99px;
  margin-top: 22px;

}
.register__scrollContent_nameBox_nameInputbox_input::placeholder{
  color: var(--accentBlue);
}

.register__scrollContent_nameBox_nameInputbox_input{
  width: 100%;
border:none;
height: 40px;
border-radius: 99px;
padding-left: 22px;

font-weight: var(--mediumWeight);
color: var(--black);
font-size: var(--mediumFontSize);
}

.register__scrollContent_interestsBox_section{
width: 100%;
margin-top: 22px;
}

.register__scrollContent_interestsBox_section_title{
  font-weight: var(--boldWeight);
  color: var(--black);
  font-size: var(--mediumFontSize);
}

.register__scrollContent_interestsBox_section_tags{
  width: 100%;
  padding: 14px;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 14px;
  flex-wrap:wrap;

}

.register__scollContent_CTAbox{
  width: 100%;
  display:flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;

  margin-top: 22px;
}